import request from "@/utils/request";

var qs = require("qs");
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */
// equment 设备管理
// 充电桩列表


export function fetchEquList(data) {
  return request({
    url: "/chargings",
    method: "get",
    params: data
  });
} // 充电桩详情

export function fetchEquDetail(id) {
  return request({
    url: "/chargings/" + id,
    method: "get"
  });
}
export function fetchChargingPortList(id) {
  return request({
    url: "/chargings/portList/" + id,
    method: "get"
  });
}
export function sendChargingControl(id, data) {
  return request({
    url: "/chargings/control/" + id,
    method: "put",
    data: data
  });
} // 添加充电桩

export function addEqu(data) {
  return request({
    url: "/chargings",
    method: "post",
    data: data
  });
} // 充电桩编辑

export function updateEqu(id, data) {
  return request({
    url: "/chargings/" + id,
    method: "put",
    data: data
  });
} // 删除充电桩

export function delEqu(data) {
  return request({
    url: "/chargings",
    method: "delete",
    data: data
  });
} // 远程抄表

export function updateEquMetter(data) {
  return request({
    url: "/meters",
    method: "post",
    data: data
  });
} // 更新固件

export function updateEquFirm(data) {
  return request({
    url: "/update_firmwares",
    method: "post",
    data: data
  });
} // 固件列表

export function fetchEquWareList(data) {
  return request({
    url: "/firmwares",
    method: "get",
    params: data
  });
} // 固件上传

export function uploadEquWare(data) {
  return request({
    url: "/firmwares",
    method: "post",
    data: data
  });
} // 删除固件

export function delEquWare(data) {
  return request({
    url: "/firmwares",
    method: "delete",
    data: data
  });
} // 通信日志

export function fetchEquComList(data) {
  return request({
    url: "/logs",
    method: "get",
    params: data
  });
} //获取设备更新列表

export function fetchFirmUpgradeList(data) {
  return request({
    url: "/firmwares/upgradeList",
    method: "get",
    params: data
  });
} // 抄表记录

export function fetchEquMeterList(data) {
  return request({
    url: "/meters",
    method: "get",
    params: data
  });
} // 抄表下载

export function fetchEquMeterEecel(data) {
  return request({
    url: "/meters/download",
    method: "get",
    params: data
  });
} // 会员卡列表

export function fetchMebCardList(data) {
  return request({
    url: "/member_cards",
    method: "get",
    params: data
  });
} // 添加会员卡

export function addMebCard(data) {
  return request({
    url: "/member_cards",
    method: "post",
    data: data
  });
} // 解绑会员卡

export function delMebBindCard(data) {
  return request({
    url: "/unbind_card",
    method: "delete",
    data: data
  });
} // 删除会员卡

export function delMebCard(data) {
  return request({
    url: "/member_cards",
    method: "delete",
    data: data
  });
} // 订单列表

export function fetchOrderList(data) {
  return request({
    url: "/orders",
    method: "get",
    params: data
  });
} // 结束订单

export function updateEndOrder(data) {
  return request({
    url: "/finish_order",
    method: "post",
    data: data
  });
} // 订单退款

export function updateReturn(data) {
  return request({
    url: "/refund",
    method: "post",
    data: data
  });
} // 下载订单

export function fetchOrderExcel(data) {
  return request({
    url: "/orders/download",
    method: "get",
    params: data
  });
} // 订单扣款记录

export function fetchOrderDeductLog(data) {
  return request({
    url: "/orders/deduct_log",
    method: "get",
    params: data
  });
} // 汽车订单费用明细

export function fetchOrderCostList(data) {
  return request({
    url: "/orders/cost_list",
    method: "get",
    params: data
  });
} // 小区列表

export function fetchPropertyList(data) {
  return request({
    url: "/property",
    method: "get",
    params: data
  });
} // 添加小区

export function addProperty(data) {
  return request({
    url: "/property",
    method: "post",
    data: data
  });
} // 编辑小区

export function updateProperty(data) {
  return request({
    url: "/property/".concat(data.id),
    method: "put",
    data: data
  });
} // 编辑小区

export function getProperty(id) {
  return request({
    url: "/property/detail/".concat(id),
    method: "get"
  });
} // 区域列表

export function fetchAreaList(code) {
  return request({
    url: "/regions",
    method: "get",
    params: {
      code: code
    }
  });
} // 删除小区

export function delProperty(id) {
  return request({
    url: "/property/".concat(id),
    method: "delete"
  });
} // 代理商列表

export function fetchAgentList(data) {
  return request({
    url: "/agents",
    method: "get",
    params: data
  });
} // 添加代理商

export function addAgent(data) {
  return request({
    url: "/agents",
    method: "post",
    params: data
  });
} // 修改代理商

export function updateAgent(data) {
  return request({
    url: "/agents/".concat(data.id),
    method: "put",
    params: data
  });
} // 删除代理商

export function delAgent(id) {
  return request({
    url: "/agents/".concat(id),
    method: "delete"
  });
} // 小区用户列表

export function fetchPropertyMemberList(data) {
  return request({
    url: "/property/member",
    method: "get",
    params: data
  });
} // 添加小区用户

export function addPropertyMember(data) {
  return request({
    url: "/property/member",
    method: "post",
    data: data
  });
} // 删除小区用户

export function delPropertyMember(id) {
  return request({
    url: "/property/member/".concat(id),
    method: "delete"
  });
} // 充值记录

export function fetchRechargeList(data) {
  return request({
    url: "/recharges",
    method: "get",
    params: data
  });
} // 用户使用习惯

export function fetchUserHabit(data) {
  return request({
    url: "/usinghabit",
    method: "get",
    params: data
  });
} // 代理商营业额排名

export function fetchAgentTurnover(data) {
  return request({
    url: "/agent_rank",
    method: "get",
    params: data
  });
} // 订单统计

export function fetchOrder(data) {
  return request({
    url: "/order_stat",
    method: "get",
    params: data
  });
} // 消费统计

export function fetchConsu(data) {
  return request({
    url: "/consumption",
    method: "get",
    params: data
  });
} // 代理树

export function fetchAgentTree() {
  return request({
    url: "/agents_tree",
    method: "get"
  });
} // 白名单列表

export function fetchWhitelistList(data) {
  return request({
    url: "/whitelist",
    method: "get",
    params: data
  });
} // 添加白名单

export function addWhitelist(data) {
  return request({
    url: "/whitelist",
    method: "post",
    data: data
  });
} // 小区用户月卡列表

export function fetchPropertyMemberMonthList(data) {
  return request({
    url: "/property/member/month",
    method: "get",
    params: data
  });
} // 添加小区用户月卡

export function addPropertyMemberMonth(data) {
  return request({
    url: "/property/member/month",
    method: "post",
    data: data
  });
} // 编辑小区用户月卡

export function updatePropertyMemberMonth(data) {
  return request({
    url: "/property/member/month/".concat(data.id),
    method: "put",
    data: data
  });
} // 删除小区用户月卡

export function delPropertyMemberMonth(id) {
  return request({
    url: "/property/member/month/".concat(id),
    method: "delete"
  });
} // 小区员工列表

export function fetchPropertyStaffList(data) {
  return request({
    url: "/property/staff",
    method: "get",
    params: data
  });
} // 添加小区员工

export function addPropertyStaff(data) {
  return request({
    url: "/property/staff",
    method: "post",
    data: data
  });
} // 编辑小区员工

export function updatePropertyStaff(data) {
  return request({
    url: "/property/staff/".concat(data.id),
    method: "put",
    data: data
  });
} // 删除小区员工

export function delPropertyStaff(id) {
  return request({
    url: "/property/staff/".concat(id),
    method: "delete"
  });
} // 报警设备列表

export function fetchAlarmDeviceList(data) {
  return request({
    url: "/alarm/device",
    method: "get",
    params: data
  });
} // 添加报警设备

export function addAlarmDevice(data) {
  return request({
    url: "/alarm/device",
    method: "post",
    data: data
  });
} // 编辑报警设备

export function updateAlarmDevice(data) {
  return request({
    url: "/alarm/device/".concat(data.id),
    method: "put",
    data: data
  });
} // 删除报警设备

export function delAlarmDevice(id) {
  return request({
    url: "/alarm/device/".concat(id),
    method: "delete"
  });
} // 报警联系人列表

export function fetchAlarmContactList(data) {
  return request({
    url: "/alarm/contact",
    method: "get",
    params: data
  });
} // 添加报警联系人

export function addAlarmContact(data) {
  return request({
    url: "/alarm/contact",
    method: "post",
    data: data
  });
} // 编辑报警联系人

export function updateAlarmContact(data) {
  return request({
    url: "/alarm/contact/".concat(data.id),
    method: "put",
    data: data
  });
} // 删除报警联系人

export function delAlarmContact(id) {
  return request({
    url: "/alarm/contact/".concat(id),
    method: "delete"
  });
} // 报警记录列表

export function fetchAlarmLogList(data) {
  return request({
    url: "/alarm/log",
    method: "get",
    params: data
  });
} // 审核报警记录

export function checkAlarmLog(data) {
  return request({
    url: "/alarm/log/".concat(data.id),
    method: "put",
    data: data
  });
} // 充电数据列表

export function fetchChargingDataList(data) {
  return request({
    url: "/orders/charging_data",
    method: "get",
    params: data
  });
}
export function fetchChargingDataDetail(id) {
  return request({
    url: "/orders/charging_data/" + id,
    method: "get"
  });
} // 充电数据列表

export function fetchOrderOccupyList(data) {
  return request({
    url: "/orders/occupy",
    method: "get",
    params: data
  });
} // 占位费列表

export function fetchOccupyTmplList(data) {
  return request({
    url: "/occupy_tmpl",
    method: "get",
    params: data
  });
}
export function addOccupyTmpl(data) {
  return request({
    url: "/occupy_tmpl",
    method: "post",
    data: data
  });
}
export function updateOccupyTmpl(id, data) {
  return request({
    url: "/occupy_tmpl/" + id,
    method: "put",
    data: data
  });
}
export function deleteOccupyTmpl(id) {
  return request({
    url: "/occupy_tmpl/" + id,
    method: "delete"
  });
}